<template>
  <div class="h-100 d-flex flex-column">
    <!------------ START: Header ------------>
    <Header
      :title="$t('textTemplates.edit', { name: textTemplate.label }).toString()"
      :subtitle="subtitle"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectTextTemplates' })"
      @save="save"
      @test="test"
      @showVersions="onShowVersions"
    />
    <!------------ END: Header ------------>
    <div class="card-body p-0">
      <div class="row mx-n1 h-100">
        <div class="col-3 pl-1 mh-100">
          <div class="card card-custom h-100">
            <div class="card-body">
              <Parameters
                :parameters="textTemplate.variables"
                :ds-data="dataStructureData"
                :ready="parametersReady"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card card-custom h-100">
            <div class="card-body">
              <CodeEditor
                v-if="showCodeEditor"
                v-model="textTemplate.content"
                :mode="textTemplate.type"
              />
            </div>
          </div>
        </div>
        <div class="col-3 pr-1 mh-100">
          <div class="card card-custom h-100">
            <div class="card-body">
              <Helper :text-template-type="textTemplate.type" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <TestModal
      v-if="parametersReady"
      ref="testModal"
      v-model="debugData"
      :text-template="textTemplate"
      :data-structure-data="dataStructureData"
    />

    <BackupModal
      v-if="textTemplate"
      v-model="showVersionsModal"
      :object="textTemplate"
      classname="TextTemplate"
    />
  </div>
</template>

<script>
import DataStructures from "@/components/DataStructures/dataStructures";
import TextTemplate from "@/components/TextTemplates/text_templates";
import CodeEditor from "@/components/TextTemplates/Components/CodeEditor.vue";
import Helper from "@/components/TextTemplates/Components/Helper.vue";
import Parameters from "@/components/TextTemplates/Components/Parameters.vue";
import _ from "lodash";
import TestModal from "@/components/TextTemplates/Components/TestModal.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { mapGetters } from "vuex";
import { formatDate } from "@/components/Tools/helperFunctions";
import { useWorkNote } from "@/composables/useWorkNote";

export default {
  components: {
    BackupModal,
    Header,
    TestModal,
    Parameters,
    Helper,
    CodeEditor
  },
  props: {},
  data() {
    return {
      textTemplate: {},
      debugData: {},
      dataStructureData: {},
      loadVariablesCount: 0,
      parametersReady: false,
      showCodeEditor: false,
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters([
      "isPresetProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        },
        {
          type: "button",
          icon: this.$store.getters["config/icons"].play,
          tooltip: this.$t("textTemplates.test"),
          emit: "test",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerButtons;
      }
      headerButtons.splice(1, 0, {
        type: "button",
        icon: this.$store.getters["config/icons"].save,
        tooltip: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerButtons;
    },
    subtitle: function () {
      if (!this.textTemplate) {
        return "";
      }

      let subtitleParts = [];
      if (this.textTemplate.updated_by_user?.full_name) {
        subtitleParts.push(this.textTemplate.updated_by_user?.full_name);
      }
      if (this.textTemplate.updated_at) {
        subtitleParts.push(formatDate(this.textTemplate.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadTextTemplate();
    }
  },
  mounted() {
    this.loadTextTemplate();
  },
  methods: {
    loadTextTemplate() {
      addEventToLoadingQueue({ key: "loadTextTemplate" });
      let textTemplateId = this.$route.params.id;
      TextTemplate.get(textTemplateId)
        .then(response => {
          // Set text template data
          this.textTemplate = response.data;
          // Set count of variables to sync asynchronous data structure data loading
          this.loadVariablesCount = this.textTemplate.variables.length;

          if (this.textTemplate.type) {
            this.demoFileName = "test." + this.textTemplate.type;
          }

          this.showCodeEditor = true;
          removeEventFromLoadingQueue({ key: "loadTextTemplate" });
          this.loadDataStructureData();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    loadDataStructureData() {
      this.textTemplate.variables.forEach(param => {
        // If variable is not a data structure
        if (!param.dataStructure) {
          // Reduce variables loading count
          this.loadVariablesCount--;
          return;
        }

        DataStructures.getData(param.dataStructure.id)
          .then(response => {
            this.$set(this.dataStructureData, param.slug, response.data);
            // Reduce variables loading count
            this.loadVariablesCount--;
          })
          .catch(error => {
            this.loadVariablesCount--;
            this.$error(error);
          })
          .finally(() => {
            // If all variables were loaded, show parameters column
            if (this.loadVariablesCount === 0) {
              this.showVariablesSelection();
            }
          });
      });
      // If all variables were loaded, show parameters column
      if (this.loadVariablesCount === 0) {
        this.showVariablesSelection();
      }
    },
    showVariablesSelection() {
      this.parametersReady = true;
    },
    back(event) {
      if (event && (event.ctrlKey || event.metaKey)) {
        this.backNewTab();
        return;
      }
      this.$router.push({ name: "projectTextTemplates" });
    },
    backNewTab() {
      const route = this.$router.resolve({
        name: "projectTextTemplates"
      });
      window.open(route.href, "_blank");
    },
    async save() {
      let data = _.pick(_.cloneDeep(this.textTemplate), [
        "label",
        "type",
        "content",
        "work_note"
      ]);
      const { addWorkNote } = useWorkNote();
      const { data: payload, success } = await addWorkNote(data);
      if (!success) {
        return;
      }
      addEventToLoadingQueue({ key: "saveTextTemplate" });
      payload.variables = this.textTemplate.variables.map(v => {
        return {
          type: v.type,
          name: v.name,
          dataStructure: v.dataStructure ? v.dataStructure.id : null
        };
      });
      TextTemplate.update(this.textTemplate.id, payload)
        .then(response => {
          this.textTemplate = response.data;
          removeEventFromLoadingQueue({
            key: "saveTextTemplate",
            type: "success",
            prefix: "textTemplates",
            name: "textTemplateSaved"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    test() {
      this.$refs.testModal.show();
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
