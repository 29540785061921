<template>
  <div>
    <Header
      :title="$t('textTemplates.detailsBasicConfiguration').toString()"
      :subtitle="subtitle"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectTextTemplates' })"
      @save="updateTextTemplate"
      @showVersions="onShowVersions"
    />
    <div class="card card-custom">
      <div class="card-body">
        <Form
          v-if="isMounted"
          ref="form"
          v-model="textTemplateData"
          :config="config"
        />
      </div>
    </div>

    <BackupModal
      v-if="textTemplate"
      v-model="showVersionsModal"
      :object="textTemplate"
      classname="TextTemplate"
    />
  </div>
</template>

<script>
import Header from "@/components/Tools/Header/Header.vue";
import Form from "@/components/TextTemplates/Components/Form.vue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { formatDate } from "@/components/Tools/helperFunctions";
import { mapGetters } from "vuex";
const _ = require("lodash");

export default {
  components: { BackupModal, Form, Header },
  props: {
    textTemplate: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      textTemplateData: null,
      form: [],
      config: {
        label: "",
        enableVariables: false,
        snippetPrefix: "textTemplates"
      },
      isMounted: false,
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters(["isPresetProject", "isDevPresetVersion"]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerButtons;
      }
      headerButtons.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerButtons;
    },
    subtitle: function () {
      if (!this.textTemplate) {
        return "";
      }

      let subtitleParts = [];
      if (this.textTemplate.updated_by_user?.full_name) {
        subtitleParts.push(this.textTemplate.updated_by_user?.full_name);
      }
      if (this.textTemplate.updated_at) {
        subtitleParts.push(formatDate(this.textTemplate.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  watch: {
    textTemplate: function () {
      if (this.isMounted || !this.textTemplate.id) {
        return;
      }
      this.setData();
      this.isMounted = true;
    }
  },
  mounted() {},
  methods: {
    setData() {
      let textTemplate = _.cloneDeep(this.textTemplate);
      textTemplate.variables.forEach(v => {
        if (!v.dataStructure) {
          return;
        }
        v.dataStructure = v.dataStructure.id;
      });
      this.textTemplateData = textTemplate;
    },
    updateTextTemplate() {
      this.$emit("update", this.textTemplateData);
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
