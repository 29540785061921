<template>
  <BasicConfiguration
    :text-template="textTemplate"
    @update="updateTextTemplate"
    @back="back"
  />
</template>

<script>
import BasicConfiguration from "@/components/TextTemplates/Details/BasicConfiguration.vue";
import TextTemplate from "@/components/TextTemplates/text_templates";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useWorkNote } from "@/composables/useWorkNote";
import { mapGetters } from "vuex";

const _ = require("lodash");

export default {
  components: { BasicConfiguration },
  props: {},
  data() {
    return {
      textTemplate: {}
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"])
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadTextTemplate();
    }
  },
  mounted() {
    this.loadTextTemplate();
  },
  methods: {
    loadTextTemplate() {
      addEventToLoadingQueue({ key: "loadTextTemplate" });
      this.textTemplate = {};
      let textTemplateId = this.$route.params.id;
      TextTemplate.get(textTemplateId)
        .then(response => {
          this.textTemplate = response.data;
          this.textTemplate.projects = this.textTemplate.projects.map(
            project => project.id
          );
          removeEventFromLoadingQueue({ key: "loadTextTemplate" });
        })
        .catch(error => {
          this.$error(error);
          removeEventFromLoadingQueue({ key: "loadTextTemplate" });
        });
    },
    async updateTextTemplate(updateData) {
      const { addWorkNote } = useWorkNote();
      const { data, success } = await addWorkNote(updateData);
      if (!success) {
        return;
      }
      addEventToLoadingQueue({ key: "loadTextTemplate" });

      const props = [
        "id",
        "label",
        "type",
        "variables",
        "projects",
        "work_note"
      ];
      let requestData = _.pick(data, props);

      requestData["variables"] = requestData["variables"].map(function (
        variable
      ) {
        variable["dataStructure"] =
          variable["dataStructure"] && variable["dataStructure"]["id"]
            ? variable["dataStructure"]["id"]
            : variable["dataStructure"];
        return variable;
      });

      TextTemplate.update(requestData.id, requestData)
        .then(response => {
          this.textTemplate = response.data;
          removeEventFromLoadingQueue({
            key: "loadTextTemplate",
            type: "success",
            prefix: "textTemplates",
            name: "textTemplateUpdated"
          });
          this.back();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    back() {
      this.$router.push({ name: "projectTextTemplates" });
    }
  }
};
</script>
