<template>
  <div>
    <FormHelper
      ref="formHelper"
      v-model="textTemplate"
      :form="form"
      :config="config"
    />
  </div>
</template>

<script>
import {
  templateTypes,
  variablesTypes
} from "@/components/TextTemplates/Components/constants";
import { mapGetters } from "vuex";
import DataStructures from "@/components/DataStructures/dataStructures";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      textTemplate: {
        label: "",
        type: "other",
        projects: [],
        variables: []
      },
      dataStructures: []
    };
  },
  computed: {
    ...mapGetters(["projects", "selectedProject"]),
    form: function () {
      return [
        {
          type: "text",
          name: "label",
          label: "label",
          validations: {
            required: true
          }
        },
        {
          type: "select",
          name: "type",
          label: "type",
          options: templateTypes.map(type => {
            return {
              label: this.$t("textTemplates.types." + type),
              value: type
            };
          }),
          default: "other",
          validations: {
            required: true
          }
        },
        {
          type: "multiselect",
          name: "projects",
          label: "projects",
          options: this.projects?.length
            ? this.projects.map(project => {
                return {
                  label: project.name,
                  value: project.id
                };
              })
            : [],
          default: [this.selectedProject.id],
          validations: {
            required: true,
            minLength: 1
          }
        },
        {
          type: "group",
          name: "variables",
          label: "parameters",
          addGroupLabel: "parametersAdd",
          fields: [
            {
              type: "text",
              name: "name",
              label: "name",
              validations: {
                required: true
              }
            },
            {
              type: "select",
              name: "type",
              label: "type",
              options: variablesTypes,
              validations: {
                required: true
              }
            },
            {
              type: "select",
              name: "dataStructure",
              label: "dataStructure",
              options: this.dataStructures,
              validations: {
                required: true
              },
              dependsOn: [
                {
                  name: "type",
                  values: ["dataStructure", "dataStructureList"]
                }
              ]
            }
          ]
        }
      ];
    }
  },
  watch: {
    textTemplate: {
      deep: true,
      handler: function () {
        this.onInput(this.textTemplate);
      }
    }
  },
  mounted() {
    Object.assign(this.textTemplate, this.value);
    this.loadDataStructures();
  },
  methods: {
    loadDataStructures() {
      DataStructures.getAll({ noPagination: true })
        .then(response => {
          response.data.forEach(ds => {
            let type = ds.owner_type.includes("DataSet")
              ? " - DataSet"
              : " - DataStructure";
            this.dataStructures.push({ label: ds.label + type, value: ds.id });
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
