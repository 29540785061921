<template>
  <b-modal
    ref="testModal"
    hide-footer
    size="xl"
    :title="
      $t('processManager.testProcess', {
        name: textTemplate.label
      })
    "
    @show="setDebugData"
    @hide="updateDebugData"
  >
    <div class="row">
      <div class="col col-lg-6 pr-12">
        <div class="font-weight-bolder mb-3">
          {{ $t("textTemplates.selectDebugData") }}:
        </div>
        <FormHelper v-model="debugData" :form="form" :options="options" />
      </div>
      <div class="col col-lg-6">
        <div class="font-weight-bolder mb-3">
          {{ $t("textTemplates.output") }}:
        </div>
        <div class="debug-result">
          <div style="white-space: pre-line">{{ debugTemplate }}</div>
        </div>
        <div
          v-if="debugValid !== null && textTemplate.type"
          class="validation mt-4"
        >
          <span v-if="debugValid" class="is-valid" style="color: #20ab20"
            >{{ $t("textTemplates.valid") }} ({{ textTemplate.type }})</span
          >
          <span v-if="!debugValid" class="is-invalid" style="color: #ff3553">
            {{ $t("textTemplates.invalid") }} ({{ textTemplate.type }})
          </span>
        </div>
        <div v-if="!debugValid && debugErrors" class="debug-errors">
          <div v-for="(error, key) in debugErrors" :key="key">
            <hr />
            <div>{{ error }}</div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-lg-6 pr-12">
        <div class="mt-7 d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ 'spinner spinner-right': isBusy }"
            @click="testTextTemplateSubmit"
          >
            {{ $t("processManager.test") }}
          </button>
        </div>
      </div>
      <div class="col col-lg-6">
        <div class="mt-7 d-flex justify-content-end">
          <input
            v-model="demoFileName"
            type="text"
            class="form-control w-25 mr-2"
          />
          <button type="submit" class="btn btn-primary" @click="download">
            {{ $t("textTemplates.download") }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TextTemplate from "@/components/TextTemplates/text_templates";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper },
  props: {
    textTemplate: {
      type: Object,
      default: () => ({})
    },
    dataStructureData: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      debugData: {},
      demoFileName: "test.txt",
      debugTemplate: "",
      debugValid: null,
      debugErrors: [],
      options: {
        label: "",
        enableVariables: false
      },
      isBusy: false
    };
  },
  computed: {
    form: function () {
      let form = [];
      if (!this.textTemplate?.variables) {
        return form;
      }
      this.textTemplate.variables.forEach(v => {
        if (v.type === "string") {
          form.push({
            type: "text",
            name: v.slug,
            label: v.name
          });
        } else {
          form.push({
            type: v.type === "dataStructure" ? "select" : "multiselect",
            name: v.slug,
            label: v.name,
            options: this.dataStructureData[v.slug].map(d => ({
              label: d._primary,
              value: d
            })),
            clearable: true
          });
        }
      });
      return form;
    }
  },
  mounted() {},
  methods: {
    setDebugData() {
      if (!Object.keys(this.value).length) {
        this.textTemplate.variables.forEach(v => {
          this.debugData[v.slug] = v.type === "dataStructureList" ? [] : "";
        });
      } else {
        this.debugData = this.value;
      }
    },
    updateDebugData() {
      this.$emit("input", this.debugData);
    },
    testTextTemplateSubmit() {
      this.isBusy = true;
      TextTemplate.execute(
        this.textTemplate.id,
        this.debugData,
        this.textTemplate.content
      )
        .then(response => {
          this.debugTemplate = response.data;

          if (response.data.valid !== undefined) {
            this.debugValid = response.valid;
            this.debugErrors = response.errors;
          } else {
            this.debugValid = true;
            this.debugErrors = [];
          }

          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    download() {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(this.debugTemplate)
      );
      element.setAttribute("download", this.demoFileName);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    show() {
      this.$refs.testModal.show();
    },
    hide() {
      this.$refs.testModal.hide();
    }
  }
};
</script>
