<template>
  <div>
    <Header
      :title="$t('textTemplates.textTemplateCreate').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectTextTemplates' })"
      @save="saveTextTemplate"
    />
    <div class="card card-custom">
      <div class="card-body">
        <Form ref="form" v-model="textTemplate" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
import TextTemplate from "@/components/TextTemplates/text_templates";
import Form from "@/components/TextTemplates/Components/Form.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  components: { Header, Form },
  props: {},
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      textTemplate: {
        label: "",
        type: "other",
        projects: [],
        variables: []
      },
      form: [],
      config: {
        label: "",
        enableVariables: false,
        snippetPrefix: "textTemplates"
      },
      isBusy: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    saveTextTemplate() {
      addEventToLoadingQueue({ key: "saveTextTemplate" });
      let errors = this.$refs.form.$refs.formHelper.validate();
      if (errors.length) return;

      let data = this.textTemplate;
      TextTemplate.store(data)
        .then(response => {
          removeEventFromLoadingQueue({
            key: "saveTextTemplate",
            type: "success",
            prefix: "textTemplates",
            name: "textTemplateSaved"
          });
          this.$router.push({
            name: "projectTextTemplatesEditor",
            params: { id: response.data.id }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
