<template>
  <div class="h-100">
    <!------------ START: Loading spinner ------------>
    <div
      v-if="!ready"
      class="d-flex justify-content-center align-items-center h-100"
    >
      <b-spinner variant="primary" style="width: 2rem; height: 2rem" />
    </div>
    <!------------ END: Loading spinner ------------>
    <div v-else class="h-100">
      <!------------ START: Parameters ------------>
      <b-tabs
        v-if="parameters.length"
        class="h-100 d-flex flex-column"
        content-class="overflow-y-auto"
      >
        <b-tab
          v-for="(param, key) in parameters"
          :key="key"
          :title="param.name"
        >
          <div id="tree-start" class="mt-3">
            <!------------ START: Param type string ------------>
            <div
              v-if="param.type === 'string'"
              class="fieldset tree-node-start tree-node px-4 py-2"
            >
              <div
                class="tree-node-content d-flex justify-content-between align-items-center cursor-pointer"
                @click="copy(param.slug)"
              >
                <div class="mr-1">
                  <i class="type-icon mr-1 fal fa-edit" />
                  <span class="mr-1">{{ param.name }}</span>
                </div>
              </div>
            </div>
            <!------------ END: Param type string ------------>
            <!------------ START: Param type data struture list ------------>
            <div
              v-if="param.type === 'dataStructureList'"
              class="fieldset tree-node-start tree-node px-2"
            >
              <div
                class="tree-node-content d-flex justify-content-between align-items-center cursor-pointer"
                @click="copyList(param.slug)"
              >
                <div class="mr-1">
                  <i class="type-icon mr-1 fal fa-folder-open" />
                  <span class="mr-1">{{ param.name }}</span>
                </div>
              </div>
            </div>
            <!------------ END: Param type data struture list ------------>
            <!------------ START: Param type data struture ------------>
            <div
              v-if="param.dataStructure"
              :class="{ 'ml-6': param.type === 'dataStructureList' }"
            >
              <Node
                v-for="(field, i) in param.dataStructure.fields.filter(
                  f => f.parent_id === null
                )"
                :key="i"
                :field="field"
                :slug="
                  param.slug +
                  (param.type === 'dataStructureList' ? '_entry' : '')
                "
                :selected-data-structure-data="dsData[param.name]"
                :source-fields="param.dataStructure.fields"
              />
            </div>
            <!------------ END: Param type data struture ------------>
          </div>
        </b-tab>
      </b-tabs>
      <!------------ END: Parameters ------------>
      <div v-else class="text-muted text-center mt-5">
        {{ $t("textTemplates.noParameters") }}
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export default {
  components: {
    Node: () => import("@/components/TextTemplates/Components/Node.vue")
  },
  props: {
    parameters: {
      type: Array,
      default: () => []
    },
    dsData: {
      type: Object,
      default: () => ({})
    },
    ready: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    copy(slug) {
      copyToClipboard(slug, "{{ ", " }}");
    },
    copyList(slug) {
      let text =
        "{% for " +
        slug +
        "_key, " +
        slug +
        "_entry in " +
        slug +
        " %}\n    \n{% endfor %}";
      copyToClipboard(text);
    }
  }
};
</script>

<style lang="scss">
#tree-start {
  .tree-node {
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .type-icon {
      font-size: 1rem;
    }

    .tree-node-content {
      height: 20px;
    }

    .btn-toggle-children {
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      i {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }

      &.open {
        i {
          transform: rotate(0deg);
        }
      }
    }
  }
}
</style>
