<template>
  <div class="h-100">
    <Editor v-if="$route.name === 'projectTextTemplatesEditor'" />
    <Create v-else-if="$route.name === 'projectTextTemplatesCreate'" />
    <Details v-else-if="$route.name === 'projectTextTemplatesDetails'" />
    <Table v-else />
  </div>
</template>

<script>
import Table from "@/components/TextTemplates/Table.vue";
import Create from "@/components/TextTemplates/Create.vue";
import Details from "@/components/TextTemplates/Details.vue";
import Editor from "@/components/TextTemplates/Editor.vue";

export default {
  components: { Table, Create, Details, Editor },
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
