import i18n from "@/core/plugins/vue-i18n";
export const helper = [
  {
    name: "twig",
    label: "textTemplates.helpers.twig.twig",
    style: "",
    showAlways: true,
    sections: [
      {
        title: "textTemplates.helpers.twig.helperFunctions",
        operators: [
          {
            title: "textTemplates.helpers.twig.condition",
            template: "{% if ____ %}{% endif %}"
          },
          {
            title: "textTemplates.helpers.twig.loop",
            template: "{% for key, entry in ____ %}"
          },
          {
            title: "textTemplates.helpers.twig.variable",
            template: "{% set ____ = ____ %}"
          },
          {
            title: "textTemplates.helpers.twig.output",
            template: "{{ ____ }}"
          }
        ]
      },
      {
        title: "textTemplates.helpers.twig.textOperators",
        operators: [
          {
            title: "textTemplates.helpers.twig.replaceText",
            template: "|replace({'____': '____'})"
          },
          {
            title: "textTemplates.helpers.twig.maskText",
            template: "|e('html')"
          },
          {
            title: "textTemplates.helpers.twig.toLowerCase",
            template: "|lower"
          },
          {
            title: "textTemplates.helpers.twig.toUpperCase",
            template: "|upper"
          },
          {
            title: "textTemplates.helpers.twig.trim",
            template: "|trim"
          },
          {
            title: "textTemplates.helpers.twig.stripHtmlTags",
            template: "|striptags"
          },
          {
            title: "textTemplates.helpers.twig.encodeUrl",
            template: "|url_encode"
          },
          {
            title: "textTemplates.helpers.twig.formatDate",
            template: "|date('m/d/Y')"
          }
        ]
      },
      {
        title: "textTemplates.helpers.twig.numberOperators",
        operators: [
          {
            title: "textTemplates.helpers.twig.numberFormat",
            template: "|number_format(2, '.', ',')"
          },
          {
            title: "textTemplates.helpers.twig.numberRound",
            template: "|round(2)"
          },
          {
            title: "textTemplates.helpers.twig.absolute",
            template: "|abs"
          }
        ]
      },
      {
        title: "textTemplates.helpers.twig.listOperators",
        operators: [
          {
            title: "textTemplates.helpers.twig.firstElement",
            template: "|first"
          },
          {
            title: "textTemplates.helpers.twig.lastElement",
            template: "|last"
          },
          {
            title: "textTemplates.helpers.twig.listLength",
            template: "|length"
          },
          {
            title: "textTemplates.helpers.twig.joinText",
            template: "|join(' ')"
          },
          {
            title: "textTemplates.helpers.twig.mergeLists",
            template: "|merge(____)"
          },
          {
            title: "textTemplates.helpers.twig.sliceList",
            template: "|slice(____,____)"
          }
        ]
      },
      {
        title: "textTemplates.helpers.twig.otherOperators",
        operators: [
          {
            title: "textTemplates.helpers.twig.countryName",
            template: "|country_name"
          },
          {
            title: "textTemplates.helpers.twig.currencyName",
            template: "|currency_name"
          },
          {
            title: "textTemplates.helpers.twig.currencySymbol",
            template: "|currency_symbol"
          },
          {
            title: "textTemplates.helpers.twig.defaultValue",
            template: "|default(____)"
          },
          {
            title: "textTemplates.helpers.twig.jsonEncode",
            template: "|json_encode()"
          }
        ]
      }
    ]
  },
  {
    name: "xml",
    label: "textTemplates.helpers.xml.xml",
    style: "",
    sections: [
      {
        title: "textTemplates.helpers.xml.helperFunctions",
        operators: [
          {
            title: "textTemplates.helpers.xml.xmlHeader",
            template: '<?xml version="1.0" encoding="utf-8" standalone="yes"?>'
          },
          {
            title: "textTemplates.helpers.xml.element",
            template: "<____></____>"
          }
        ]
      }
    ]
  },
  {
    name: "edifact",
    label: "textTemplates.helpers.edifact.edifact",
    style: ""
  },
  {
    name: "html",
    label: "textTemplates.helpers.html.html",
    style: "",
    sections: [
      {
        title: "textTemplates.helpers.html.helperFunctions",
        operators: [
          {
            title: "textTemplates.helpers.html.htmlFramework",
            template:
              "<!doctype html>\n" +
              "\n" +
              '<html lang="en">\n' +
              "<head>\n" +
              '  <meta charset="utf-8">\n' +
              '  <meta name="viewport" content="width=device-width, initial-scale=1">\n' +
              "\n" +
              "  <title>yedi TextTemplate</title>\n" +
              '  <meta name="description" content="">\n' +
              '  <meta name="author" content="yedi">\n' +
              "\n" +
              '  <link rel="icon" href="/favicon.ico">\n' +
              '  <link rel="icon" href="/favicon.svg" type="image/svg+xml">\n' +
              '  <link rel="apple-touch-icon" href="/apple-touch-icon.png">\n' +
              "\n" +
              "</head>\n" +
              "\n" +
              "<body>\n" +
              "</body>\n" +
              "</html>"
          }
        ]
      }
    ]
  }
];
export const templateTypes = [
  "csv",
  "xml",
  "html",
  "edifact",
  "datanorm",
  "json",
  "other"
];

export const variablesTypes = [
  {
    label: i18n.t("textTemplates.variablesTypes.string"),
    value: "string"
  },
  {
    label: i18n.t("textTemplates.variablesTypes.dataStructure"),
    value: "dataStructure"
  },
  {
    label: i18n.t("textTemplates.variablesTypes.dataStructureList"),
    value: "dataStructureList"
  }
];
