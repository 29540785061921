import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";
let url = process.env.VUE_APP_API_ADMIN;

class TextTemplates extends BaseService {
  get(id) {
    return ApiService.get(url, "text_templates/" + id);
  }
  execute(id, data, template = null) {
    return ApiService.post(url + "/text_templates/" + id + "/execute", {
      data: data,
      template: template
    });
  }
  getAll(params, filter = []) {
    let filterParams = this.getParams(filter, params);
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "text_templates?" + options + filterParams);
  }
  update(id, data) {
    return ApiService.put(url + "/text_templates/" + id, data);
  }
  store(data) {
    return ApiService.post(url + "/text_templates", data);
  }
  delete(id) {
    return ApiService.delete(url + "/text_templates/" + id);
  }
  exportCsv(params) {
    return ApiService.post(url + "/text_templates/export_csv", params);
  }
}
export default new TextTemplates();
