<template>
  <div class="h-100">
    <b-tabs class="h-100 d-flex flex-column" content-class="overflow-y-auto">
      <b-tab v-for="(type, i) in activeTypes" :key="i" :title="$t(type.label)">
        <HelperSection :sections="type.sections" class="mt-3" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { helper } from "@/components/TextTemplates/Components/constants";
export default {
  components: {
    HelperSection: () =>
      import("@/components/TextTemplates/Components/HelperSection")
  },
  props: {
    textTemplateType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fileTypes: helper
    };
  },
  computed: {
    activeTypes() {
      return this.fileTypes.filter(type => {
        return type.name === this.textTemplateType || type.showAlways;
      });
    }
  }
};
</script>

<style lang="scss">
.helper-section {
  i {
    color: #5e6278;
    margin-right: 8px;
  }
  ul.helper-section-list {
    list-style-type: none;
    padding-left: 0;

    li {
      cursor: pointer;

      &:hover {
        color: var(--primary);
        i {
          color: var(--primary);
        }
      }
    }
  }
}
</style>
