<template>
  <div>
    <Header
      :title="$t('textTemplates.textTemplates').toString()"
      :items="headerButtons"
      @create="createTextTemplate"
    />
    <TableHelper
      ref="table"
      :items="textTemplates"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="true"
      :filterable="filterable"
      default-order-by="label"
      :exportable="true"
      loading-key="loadTextTemplates"
      @reload-data="loadTextTemplates"
      @open="openTextTemplateEditor"
      @edit="openDetails"
      @showVersions="onShowVersions"
      @delete="deleteTextTemplate"
      @export-csv="exportCsv"
    />
    <BackupModal
      v-model="showVersionsModal"
      :object="selectedTextTemplate"
      classname="TextTemplate"
    />
  </div>
</template>

<script>
import TextTemplates from "@/components/TextTemplates/text_templates";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

export default {
  components: { BackupModal, TableHelper, Header },
  props: [],
  data() {
    return {
      textTemplates: [],
      fields: [
        {
          key: "label",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "type",
          label: this.$t("textTemplates.type"),
          sortable: true,
          formatter: type => this.$t("textTemplates.types." + type)
        }
      ],
      // Meta info
      meta: {},
      selectedTextTemplate: null,
      showVersionsModal: false,
      // Filterable fields
      filterable: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters("instance", ["instance"]),
    ...mapGetters([
      "selectedProject",
      "isPresetProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      const headerButtons = [
        {
          type: "button",
          title: this.$t("textTemplates.textTemplateCreate"),
          icon: "fa-light fa-plus",
          emit: "create"
        }
      ];
      return this.isPresetProject || !this.isDevPresetVersion
        ? []
        : headerButtons;
    },
    actions: function () {
      let actions = [
        {
          key: "open",
          icon: this.$store.getters["config/icons"].editor,
          emit: "open",
          tooltip: this.$t("general.open")
        },
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("general.edit")
        },
        {
          key: "versions",
          icon: this.$store.getters["config/icons"].version,
          emit: "showVersions"
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return actions;
      }
      actions.push({
        key: "delete",
        icon: this.$store.getters["config/icons"].delete,
        emit: "delete",
        tooltip: this.$t("general.delete")
      });
      return actions;
    }
  },
  watch: {
    selectedProject: function () {
      this.loadTextTemplates();
    },
    selectedPresetVersion: function () {
      this.loadTextTemplates();
    }
  },
  mounted() {
    this.loadTextTemplates();
  },
  methods: {
    loadTextTemplates(payload = {}) {
      this.textTemplates = [];

      if (this.instance?.isPresetInstance && !this.selectedPresetVersion) {
        return;
      }

      addEventToLoadingQueue({ key: "loadTextTemplates" });

      let params = { ...this.params(), ...payload };
      const { filter } = useCurrentProjectFilter();
      TextTemplates.getAll(params, filter.value)
        .then(response => {
          this.textTemplates = response.data;
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
          removeEventFromLoadingQueue({ key: "loadTextTemplates" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    createTextTemplate() {
      this.$router.push({ name: "projectTextTemplatesCreate" });
    },
    openTextTemplateEditor(item) {
      this.$router.push({
        name: "projectTextTemplatesEditor",
        params: { id: item.item.id }
      });
    },
    openDetails(item) {
      this.$router.push({
        name: "projectTextTemplatesDetails",
        params: { id: item.item.id }
      });
    },
    onShowVersions(data) {
      this.selectedTextTemplate = data.item;
      this.showVersionsModal = true;
    },
    deleteTextTemplate(item) {
      let params = {
        title: this.$t("textTemplates.deleteTextTemplateTitle", {
          title: item.item.label
        }),
        text: this.$t("textTemplates.deleteTextTemplateText")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) {
          return;
        }
        addEventToLoadingQueue({ key: "deleteTextTemplate" });
        TextTemplates.delete(item.item.id)
          .then(() => {
            removeEventFromLoadingQueue({
              key: "deleteTextTemplate",
              type: "success",
              prefix: "textTemplates",
              name: "textTemplateDeleted"
            });
            this.loadTextTemplates();
          })
          .catch(error => {
            this.$error(error);
          });
      });
    },
    exportCsv(params) {
      TextTemplates.exportCsv(params).catch(error => {
        this.$error(error);
      });
    }
  }
};
</script>
